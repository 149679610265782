import React from "react"
import VisibilitySensor from "react-visibility-sensor"

import { BtnInner } from "../../elements/btn-inner"
import { HexagonTitle } from "../../elements/hexagon-title"
import { SectionTitle } from "../../elements/section-title"
import { RevealImage } from "../../elements/reveal-image"

import { fallbackFile, seoClassPrefix } from "../../helpers/common"

import { CareersSectionMain } from "../../styles/careers/careers.styles"

export const CareersSection = ({
  sectionNum,
  name,
  linkUri,
  linkText,
  firstTitle,
  leftImage,
  rightImage,
  list,
  tableHeaders,
  allPositionsLinkType,
  allPositionsEmail,
}) => {
  return (
    <VisibilitySensor partialVisibility={true} offset={{ top: 100, bottom: 100 }}>
      {({ isVisible }) =>
        <CareersSectionMain id="positions" className={`to-animate ${isVisible ? 'is-in-vp' : ''}`}>
          <div className="flex-wrap">
            <div className="image-1-wrap">
              <RevealImage alt="Brevan Howard Careers" image={rightImage} variation="from-left image-1" />
            </div>
            <div className="image-2-wrap">
              <RevealImage alt="Brevan Howard Careers" image={leftImage} variation="from-left image-2" />
            </div>
          </div>
          <div className="flex-wrap">
            <div className="main-col">

              <HexagonTitle number={sectionNum}>
                {name}
              </HexagonTitle>

              <SectionTitle text={firstTitle} responsive={[{ breakpoint: 480, charLimit: 10 }]} />
              {(allPositionsLinkType === 'email') ?
                <a href={`mailto:${allPositionsEmail}`} className={`btn secondary ${seoClassPrefix}careers-all-pos-link maito`}>
                  <BtnInner text={linkText} />
                </a>
                :
                <a href={linkUri} className={`btn secondary ${seoClassPrefix}careers-all-pos-link doc`} target="_blank" rel="noreferrer">
                  <BtnInner text={linkText} />
                </a>
              }

              <section id="positions" className="list-wrap">
                <div className="list">
                  <ul>
                    <li>
                      <p>
                        {tableHeaders.leftText}
                      </p>
                      <p>
                        {tableHeaders.rightText}
                      </p>
                    </li>
                    {list.length === 0 && `There are no featured positions.`}
                    {list.map(({ position, location, file, useStaticFile, type, email }, index) => (
                      <li key={index} style={{ transitionDelay: `${300 + index * 200}ms` }}>
                        <p className="position">
                          {type ?
                            (
                              <a href={fallbackFile(file?.localStaticUrl, useStaticFile)} target="_blank" rel="noreferrer" className={`${seoClassPrefix}single-position-link`}>{position}</a>
                            ) :
                            (
                              <a href={`mailto:${email || "careers@brevanhoward.com"}`} className={`${seoClassPrefix}single-position-link`}>{position}</a>
                            )
                          }
                        </p>
                        <p className="city">{location}</p>
                      </li>
                    ))}
                  </ul>
                </div>
              </section>
            </div>
          </div>
        </CareersSectionMain>
      }
    </VisibilitySensor >
  )
}
