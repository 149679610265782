import React from 'react'
import VisibilitySensor from "react-visibility-sensor"

import { SectionText } from "../../elements/section-text"
import { RevealImage } from "../../elements/reveal-image"

import { TestimonialsHeaderMain } from "../../styles/careers/testimonials-header.styles";

export const TestimonialsHeader = ({ text, headerImage }) => {
  return (
    <TestimonialsHeaderMain>
      <VisibilitySensor partialVisibility={true} offset={{ top: 100, bottom: 100 }}>
        {({ isVisible }) =>
          <div className={`top-col to-animate ${isVisible ? 'is-in-vp' : ''}`}>
            <div className="testim-header-wrap flex-wrap">
              <div className="testim-header-text">
                <SectionText text={text} variation="large-text" />
              </div>
              <div className="img-wrap">
                <RevealImage alt="Brevan Howard Testimonial" image={headerImage} variation={'from-left'} />
              </div>
            </div>
          </div>
        }
      </VisibilitySensor>
    </TestimonialsHeaderMain>
  )
}
