import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { CareersStatsSection } from "../components/careers-stats-section"
import { CareersSection } from "../components/careers/careers-section"
import { BannerSection } from "../components/banner-section"

import { fallbackFile, processHexagonData } from "../helpers/common"
import { TestimonialsHeader } from "../components/careers/testimonials-header-section"
import { JoinOurTeamCareers } from "../components/careers/join-our-team-section"
import { DiversityAndInclusion } from "../components/careers/diversity-and-inclusion-section"
import { TestimonialsSection } from "../components/testimonials-section"

const CareersTemplate = ({
  data: {
    wpgraphql: {
      page: { title, careersacf },
    },
  },
}) => {
  const fileAllPos = fallbackFile(
    careersacf.allPositionsFile?.localStaticUrl,
    careersacf.useStaticFileForAllPositions
  )

  const hexagonData = processHexagonData([
    careersacf.careersStats.name,
    careersacf.diversitySection.name,
    careersacf.joinUsOurTeam.name,
    !careersacf.featuredPositionsVisibility
      ? ""
      : careersacf.featuredPositionsSectionName,
    careersacf.banner.name,
  ])
  return (
    <Layout>
      <SEO
        title={title}
        description={
          "Explore career opportunities at Brevan Howard and join a team dedicated to excellence in global macro investment management."
        }
        canonical={"https://www.brevanhoward.com/careers"}
      />

      <CareersStatsSection
        hexagonData={hexagonData[0]}
        title={careersacf.careersStats.title}
        text1={careersacf.careersStats.columnTextLeft}
        text2={careersacf.careersStats.columnTextRight}
        linkData={careersacf.careersStats.acfLink}
        listSmall={careersacf.careersStats.listSmall}
        number1={careersacf.careersStats.listLarge[0]}
      />

      <TestimonialsHeader
        text={careersacf.testimonialsHeader.text}
        headerImage={careersacf.testimonialsHeader.image}
      />

      <TestimonialsSection list={careersacf.testimonialsSection.list} />

      <DiversityAndInclusion
        hexagonData={hexagonData[1]}
        title={careersacf.diversitySection.title}
        text={careersacf.diversitySection.text}
        image1={careersacf.diversitySection.imagesLeft[0].image}
        image2={careersacf.diversitySection.imagesLeft[1].image}
        image3={careersacf.diversitySection.imagesRight[0].image}
        image4={careersacf.diversitySection.imagesRight[1].image}
      />

      <JoinOurTeamCareers
        hexagonData={hexagonData[2]}
        title={careersacf.joinUsOurTeam.title}
        items={careersacf.joinUsOurTeam.items}
      />

      {careersacf.featuredPositionsVisibility === true && (
        <CareersSection
          hexagonData={hexagonData[3]}
          firstTitle={careersacf.featuredPositionsTitle}
          linkUri={fileAllPos}
          allPositionsLinkType={careersacf.allPositionsLinkType}
          allPositionsEmail={careersacf.allPositionsEmail}
          linkText={careersacf.featuredPositionsLinkText}
          leftImage={careersacf.fpImageTopRight}
          rightImage={careersacf.fpImageBottomLeft}
          tableHeaders={{
            leftText: "Position",
            rightText: "Location",
          }}
          list={careersacf.featuresPositionsList}
        />
      )}

      <BannerSection
        hexagonData={hexagonData[4]}
        title={careersacf.banner.title}
        linkData={careersacf.banner.acfLink}
        background={careersacf.banner.image}
        backgroundMobile={careersacf.banner.imageMobile}
        linkClass={"careers"}
      />
    </Layout>
  )
}

export default CareersTemplate

export const homeTemplateQuery = graphql`
  query CareersQuery($id: ID!) {
    wpgraphql {
      page(id: $id) {
        title
        careersacf {
          featuredPositionsVisibility
          featuredPositionsLinkText
          featuredPositionsSectionName
          featuredPositionsTitle
          fpImageTopRight {
            mediaItemUrl
            mediaItemId
            modified
            localImageFile {
              childImageSharp {
                original {
                  src
                  width
                }
                fluid(maxWidth: 250) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fpImageBottomLeft {
            mediaItemUrl
            mediaItemId
            modified
            localImageFile {
              childImageSharp {
                original {
                  src
                  width
                }
                fluid(maxWidth: 330) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }

          testimonialsHeader {
            text
            image {
              mediaItemUrl
              mediaItemId
              modified
              localImageFile {
                childImageSharp {
                  original {
                    src
                    width
                  }
                  fluid(maxWidth: 1600) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          testimonialsSection {
            list {
              quoteLabel
              quoteName
              quoteText
              image {
                mediaItemUrl
                mediaItemId
                modified
                localImageFile {
                  childImageSharp {
                    original {
                      src
                      width
                    }
                    fluid(maxWidth: 190) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              imageMobile {
                mediaItemUrl
                mediaItemId
                modified
                localImageFile {
                  childImageSharp {
                    original {
                      src
                      width
                    }
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
          diversitySection {
            name
            title
            text
            imagesLeft {
              image {
                mediaItemUrl
                mediaItemId
                modified
                localImageFile {
                  childImageSharp {
                    original {
                      src
                      width
                    }
                    fluid(maxWidth: 400) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
            imagesRight {
              image {
                mediaItemUrl
                mediaItemId
                modified
                localImageFile {
                  childImageSharp {
                    original {
                      src
                      width
                    }
                    fluid(maxWidth: 400) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
          useStaticFileForAllPositions
          allPositionsLinkType
          allPositionsEmail
          allPositionsFile {
            mediaItemId
            mediaItemUrl
            modified
            localStaticUrl
          }
          joinUsOurTeam {
            name
            title
            items {
              title
              text
              image {
                mediaItemUrl
                mediaItemId
                modified
                localImageFile {
                  childImageSharp {
                    original {
                      src
                      width
                    }
                    fluid(maxWidth: 560) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              acfLink {
                externalUrl
                fieldGroupName
                linkText
                type
                openInNewWindow
                linkToPage {
                  ... on WPGraphQL_Page {
                    uri
                  }
                }
              }
            }
          }
          featuredPositionsEmpty
          featuresPositionsList {
            location
            position
            type
            email
            useStaticFile
            file {
              mediaItemId
              modified
              mediaItemUrl
              localStaticUrl
            }
          }
          careersStats {
            columnTextLeft
            columnTextRight
            name
            title
            acfLink {
              externalUrl
              fieldGroupName
              linkText
              type
              openInNewWindow
              linkToPage {
                ... on WPGraphQL_Page {
                  uri
                }
              }
            }
            listSmall {
              title
            }
            listLarge {
              symbol
              number
              label
            }
          }

          banner {
            name
            title
            image {
              mediaItemUrl
              mediaItemId
              modified
              localImageFile {
                childImageSharp {
                  original {
                    src
                    width
                  }
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            imageMobile {
              mediaItemUrl
              mediaItemId
              modified
              localImageFile {
                childImageSharp {
                  original {
                    src
                    width
                  }
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            acfLink {
              externalUrl
              fieldGroupName
              linkText
              type
              openInNewWindow
              linkToPage {
                ... on WPGraphQL_Page {
                  uri
                }
              }
            }
          }
        }
      }
    }
  }
`
